/*
  ENTRY POINT FOR STANDALONE TRACKING
  /!\/!\/!\/!\/!\/!\/!\/!\
    YOU NEED TO HANDLE THE CONSENT MANAGEMENT BY YOURSELF !
    USER MUST HAVE CONSENTED BEFORE USING THIS SCRIPT
  /!\/!\/!\/!\/!\/!\/!\/!\
  See: demo/standalone-tracking.html
*/
import { initConfig } from 'V2Src/Actions/ConfigActions';
import {
  addIframeForCookieSync,
  handleExtendOrRenewSession,
  setAdsOptOut,
  setStatsAndAnonymousOptOut,
} from 'V2Src/Actions/PrivacyActions';
import store from 'V2Src/Actions/store';
import DataLayer from 'V2Src/DataLayer/DataLayer';
import { initTrackingtoolHelper } from 'V2Src/PrivacyManager/helper';
import { addTealiumScript } from 'V2Src/Integrations/Tealium';
import { getBrandQueryString } from 'V2Src/Utils';

const scriptTag =
  document.currentScript ||
  document.getElementById('privacy-manager') ||
  document.getElementById('tt-script') ||
  document.getElementById('om-tracking'); // 'om-tracking' is for retrocompatibility
const tealiumKey = scriptTag?.getAttribute('data-tealium-key');
const channel = scriptTag?.getAttribute('data-channel') || '';
const locale = scriptTag?.getAttribute('data-locale') || 'fr'; // default to FR for retrocompatibility
const zone = scriptTag?.getAttribute('data-zone') || 'fr'; // default to FR for retrocompatibility
const src = scriptTag?.getAttribute('src');
const brandQueryString = getBrandQueryString(src);
const brand = scriptTag?.getAttribute('data-brand') || brandQueryString || '';
const ttDataLayer = new DataLayer(channel);

initConfig(store, false, tealiumKey, zone, locale, brand);
initTrackingtoolHelper();

// Expose actions on window to be called by user in the console or by the app whenever they want
window.ttStore = store;
window.ttDataLayer = ttDataLayer;
window.setPreferenceAnalytics = (value) => setStatsAndAnonymousOptOut(store, value);
window.setPreferenceAds = (value) => setAdsOptOut(store, value);

// 3- Iframe is added to sync user prefs stored in cookies
addIframeForCookieSync(store, () => {
  // 4- Stop the script if the user has not given his consent only for Didomi
  if (window.Didomi && window.Didomi?.shouldUserStatusBeCollected()) {
    return;
  }

  // 5- Tealium scripts are loaded even without the user's consent BUT not fired
  if (tealiumKey) {
    addTealiumScript(store);
  }

  // 6- Extend or renew the user session when the page is visible every X minutes
  // Use case: a user is playing a video for 2 hours, we need to extend his session
  handleExtendOrRenewSession(store);
});
